import { Route, Routes } from 'react-router-dom'
import GetPhone from '../page/getPhone/getPhone'

  export default function App() {
    return (
      <Routes>
        <Route path='/*' element={<GetPhone />} />
      </Routes>
    )
  }
